import React from 'react';

const CodePen = () => {
  return ( 
    <svg className="codePenIcon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" >
    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <path d="M975.4,320.4L518.1,15.5c-11-7.3-25.3-7.3-36.2,0L24.5,320.4c-9.1,6-14.5,16.3-14.5,27.2v304.9c0,10.9,5.5,21.1,14.5,27.2l457.3,304.9c5.5,3.7,11.8,5.5,18.1,5.5c6.3,0,12.6-1.8,18.1-5.5l457.3-304.9c9.1-6,14.5-16.3,14.5-27.2V347.6C990,336.7,984.5,326.4,975.4,320.4L975.4,320.4z M500,613.2L330.2,500L500,386.8L669.7,500L500,613.2z M532.6,330.1V103.7l365.8,243.9L728.6,460.8L532.6,330.1L532.6,330.1z M467.3,330.1l-196,130.7L101.5,347.6l365.8-243.9V330.1z M212.4,500L75.3,591.4V408.6L212.4,500z M271.3,539.3l196,130.7v226.4L101.5,652.5L271.3,539.3z M532.6,669.9l196-130.7l169.8,113.2L532.6,896.3V669.9z M787.5,500l137.1-91.4v182.8L787.5,500z" />
  </svg>
   );
}
 
export default CodePen;