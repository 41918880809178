import React from 'react';

const Gramophone = () => {
  return ( 
    <div className="homeIcon">
      <svg  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 512 512" >
        <polygon points="419.13,282.573 419.225,282.868 419.321,282.66 		"/>
        <polygon points="349.845,67.406 349.958,67.758 350.081,67.54 		"/>
        <path d="M244.724,371.89c6.077-4.565,10.008-11.83,10.008-20.016c0-13.818-11.202-25.02-25.02-25.02
          c-8.185,0-15.451,3.931-20.016,10.008h-71.864l-0.058-33.996c0.732-0.236,1.493-0.48,2.28-0.734
          c18.161-5.832,55.955-17.966,58.21-18.648c59.525-17.292,123.341-12.589,179.695,13.241l19.994,9.165
          c2.601,3.534,5.174,6.62,7.646,9.583c7.302,8.752,13.608,16.309,19.579,34.854l28.579-9.202
          c-7.76-24.098-16.974-35.142-25.104-44.885c-3.094-3.709-6.009-7.206-8.795-11.412c-3.79-5.72-7.343-12.756-10.783-23.442
          c-3.441-10.682-4.658-18.465-4.918-25.319c-0.192-5.046,0.131-9.591,0.48-14.41c0.917-12.657,1.956-27.002-5.804-51.1
          c-7.76-24.097-16.974-35.141-25.104-44.884c-3.093-3.708-6.008-7.204-8.795-11.409c-3.79-5.72-7.343-12.755-10.783-23.442
          c-3.44-10.681-4.657-18.463-4.918-25.317c-0.192-5.045,0.131-9.591,0.48-14.409c0.917-12.656,1.956-27.001-5.803-51.097
          l-28.579,9.202c5.972,18.543,5.26,28.359,4.437,39.726c-0.28,3.865-0.57,7.891-0.618,12.302l-11.363,20.058
          c-13.532,23.889-45.988,52.779-91.389,81.35c-38.209,24.045-82.759,45.956-116.189,57.16l-41.967,13.821v133.268h0.1
          c2.218,51.924,44.052,93.758,95.975,95.975v19.115h-17.013V512h298.902v-30.024h-17.013v-61.715h17.013v-30.024H244.724V371.89z
          M214.7,390.236h-77.395v30.024h17.013v12.531c-3.542-0.216-7.013-0.694-10.396-1.417c-29.676-6.347-52.472-31.449-55.339-62.256
          c-0.069-0.741-0.125-1.485-0.17-2.233h121.282c1.423,1.894,3.108,3.581,5.004,5.004V390.236z"/>
        </svg>
      </div>
   );
}
 
export default Gramophone;